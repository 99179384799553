@import '@crystal-eyes/helpers.scss';
@import '@crystal-eyes/constants.scss';

.meetingDrawer {
  box-shadow:
    5px 5px 10px rgba(0, 0, 0, 0.1),
    -5px -5px 10px rgba(0, 0, 0, 0.1),
    5px -5px 10px rgba(0, 0, 0, 0.1);
  min-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-left: $side-nav-width;
  min-width: calc(95% - $side-nav-width);

  @media (max-width: 1080px) {
    margin-left: 0;
    min-width: 95%;
  }

  .requestButton {
    cursor: pointer;
    color: $action-blue;

    &:hover {
      opacity: 0.87;
    }
  }

  .buttonWrapper {
    margin-right: 8px;
  }

  .subHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.flatDrawerHeader {
  display: flex;
  justify-content: right;
  align-items: center;
}
