@import '@crystal-eyes/constants.scss';

.container {
  text-align: center;
  margin: 64px auto;
  color: $material-87;
  max-width: 800px;
  &.blueSmall {
    @media (max-width: 600px) {
      margin: 48px 0;
    }
  }
  &.simpleLayout {
    margin: 32px 0 0;
  }
  &.noTopMargin {
    margin: 0 auto 32px;
    .header {
      margin-top: 0;
    }
  }
  &.hasLanguageDropdown {
    margin-bottom: 22px;
  }
}

.header {
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 16px;
  color: $material-87;

  &.blueSmall {
    @media (max-width: 600px) {
      font-size: 28px;
      font-family: var(--font-lato);
      font-weight: 400;
      line-height: 24px;
      color: $blue;
      margin-bottom: 16px;
      padding-bottom: 24px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: calc(50% - 50px);
        bottom: 0;
        height: 1px;
        width: 100px;
        background-color: $material-12;
      }
    }
  }
}

.text {
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6em;
  margin: 2px 16px 0;
  color: $material-54;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.subtitle {
  font-family: var(--font-lato);
  font-weight: 300;
  font-size: 16px;
}

.bold {
  font-family: var(--font-lato);
  font-weight: 700;
  display: inline;
}
