@import '@crystal-eyes/constants.scss';

.checkboxItem {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  color: $gray-text;
  font-size: 1rem;
  background: $white;
  border-top: 1px solid $gray-background;
}

.checkboxItemLabels {
  display: flex;
  align-items: center;
}

.checkboxItemInput {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid $gray-text;
  cursor: pointer;
}

.checkboxItemCircle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: $white;
  margin-right: 8px;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
}
