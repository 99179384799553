@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;

  @media (max-width: 600px) {
    height: 250px;
  }
}

.select {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin-top: auto;
  margin-bottom: 25px;
}

.ellipsis-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.hidden {
  display: none;
  visibility: hidden;
}
