@import '@crystal-eyes/constants.scss';

.alertBanner {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  background: #fff;
  padding: 12px 16px;
  border-radius: 3px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  font-size: 1.125rem;
  line-height: 1.4rem;
  align-items: center;

  .alertBannerIcon {
    height: 18px;
    width: 18px;
    stroke: none;
  }
}
