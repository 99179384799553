@import '@crystal-eyes/constants.scss';

.success {
  display: flex;
  margin-bottom: 0.5rem;
  svg {
    height: 36px;
    width: 36px;
    z-index: 1;
    circle {
      fill: $action-blue !important;
    }
  }
}

.successMessage {
  background: $white;
  border: 1px solid $action-blue;
  border-radius: 100px;
  color: $action-blue;
  font-family: var(--font-lato);
  font-weight: 700;
  font-style: normal;
  line-height: 0.875rem;
  letter-spacing: 0.16em;
  padding: 5px 9px 5px 30px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  left: -1.4rem;
  height: 0.875rem;
  top: 0.3rem;
}
