@import '@crystal-eyes/constants.scss';

$line-color: #eee;

.traits {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-size: 0.8rem;

  :global(.disc-line-wrapper) {
    margin-top: 2em;
    margin-bottom: 2em;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
