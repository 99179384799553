@import '@crystal-eyes/constants.scss';

.container {
  display: flex;

  @media (max-width: 600px) {
    &.responsiveJustifyCenter {
      justify-content: center;
    }

    &.responsiveVertical {
      flex-direction: column;
    }
  }
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.vertical {
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignTop {
  align-items: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.spaceBetween {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.columnSmall {
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.column {
  flex-direction: column;
}
