@import '@crystal-eyes/constants.scss';

.hidden {
  display: none;
}

.filterSection {
  overflow: auto;
  margin-bottom: 83px;
}
