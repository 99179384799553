@import '@crystal-eyes/constants.scss';

.button {
  background: $action-blue;
  border-radius: 40px;
  border: none;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  z-index: 10;

  &.secondary {
    background: $white;
    border: 1px solid $action-blue;
    color: $action-blue;
  }

  &.invisible {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    letter-spacing: normal !important;
    position: static !important;
    text-transform: none !important;
  }

  &.transparent {
    background: none;
    color: $white;
    border: 1px solid $white;
  }

  &:active {
    background: $crystal-midnight-blue;
    opacity: 0.5;
  }

  &:hover {
    background: $crystal-midnight-blue;
    text-decoration: none;
  }

  &.transparent:hover {
    background: none;
  }

  &.disabled {
    background: gray !important;
    color: rgba(255, 255, 255, 0.5) !important;
    pointer-events: none;
    cursor: default;
  }

  &.loading,
  &.success {
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    pointer-events: none;
  }

  &.success {
    background: $action-blue !important;
    color: $white !important;
  }
}

.button-wrapper {
  position: relative;

  &.invisible {
    display: inline;
  }
}

.success-message {
  background: $white;
  border: 1px solid $action-blue;
  border-radius: 100px;
  color: $action-blue;
  font-family: var(--font-lato);
  font-weight: 700;
  font-style: normal;
  font-size: 9px;
  left: 6px;
  line-height: 14px;
  letter-spacing: 0.16em;
  padding: 5px 9px 5px 30px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
  top: 4px;
  overflow: hidden;
}

.error-message {
  color: $red;
  font-size: 14px;
  font-family: var(--font-lato);
  font-weight: 300;
  font-style: italic;
  margin-top: 10px;
}

@keyframes scaleIn {
  0% {
    width: 60px;
    padding: 5px 9px 5px 30px;
    left: 15px;
  }
  70% {
    width: 180px;
    padding: 5px 9px 5px 30px;
    left: 15px;
  }
  100% {
    width: 0px;
    left: 2px;
    padding: 0px;
  }
}
