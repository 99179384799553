@import '@crystal-eyes/constants.scss';

.sidebar {
  position: fixed;
  top: 0;
  left: -600px;
  width: 500px;
  height: 100%;
  background: #f7f7f7;
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 200;

  &.open {
    left: 0;
  }
}

.sidebarHeader {
  justify-content: space-between;
  align-items: center;
}

.sidebarSection {
  display: flex;
  padding: 20px;
  background: $white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.sidebarSubSection {
  display: flex;
  gap: 8px;
}

.sidebarHeaderTxt {
  font-size: 1.714rem;
  color: $blue;
}

.hidden {
  display: none;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  background: $white;
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: 32px;
  padding: 24px 0;
  border-top: 1px solid #e3e3e3;
}
