@import '@crystal-eyes/constants';

.shareJob {
  background-color: #fafafa;
}

.editMain {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  gap: 8px;
}

.group {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 282px;

  &.noPadding {
    padding: 0;
    padding-right: 10px;
  }
}

.share {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}

.removeShare {
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.jobDescription {
  width: 680px;
}
