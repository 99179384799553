@import '@crystal-eyes/constants.scss';

button.closeBtn {
  background-color: transparent;
  color: $action-blue;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 2rem;
  font-weight: 300;
  &:after {
    content: '\d7';
  }
  &:active {
    opacity: 0.5;
  }
}
