@import '@crystal-eyes/constants';

.drawerNav {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  border-bottom: 1px solid #ececec;
  font-weight: 700;
  font-size: 0.75rem;

  div:hover {
    cursor: pointer;
    color: rgb(44, 167, 228);
  }
}

.selected {
  color: #1e749f;
}

.disabled {
  color: gray;
  cursor: not-allowed;
}

.delete {
  color: red;
}
