@import '@crystal-eyes/constants.scss';

.okta {
  width: 50%;
  max-width: 800px;
  min-width: 500px;
  padding: 80px 30px 30px;
  box-sizing: border-box;

  column-gap: 60px;
  justify-content: center;
  align-items: center;
}

.oktaArea {
  width: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
}
