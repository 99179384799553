.container {
  display: flex;
  align-items: center;
  justify-content: center;
  &.padded {
    padding: 2px;
  }
}

.array {
  display: flex;
  flex-wrap: wrap;
  margin: 48px 0;
  svg {
    max-height: 100%;
  }
}
