@import '@crystal-eyes/constants.scss';

.sectionHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  color: $gray-text;
  font-size: 1rem;
  line-height: 1.28rem;
  border-top: 1px solid $gray-background;
  background: $white;
  cursor: pointer;
}

.chevron {
  margin-top: 2px;
}
