@import '@crystal-eyes/constants.scss';

.container {
  padding-bottom: 48px;
  flex: 1;
  position: relative;
  background-color: $crystal-background-gray;
  &.simpleLayout {
    padding: 0 24px 24px;
  }
  &.floating {
    background-color: transparent;
    padding: 0;
  }
}

.error {
  color: $red;
  font-size: 20px;
  margin: 0 16px 12px 0;
  text-align: center;
}

.questions-wrapper {
  max-width: 800px;
  margin: 0 auto 16px auto;
  &.floating {
    margin: 0 auto 16px;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.05));
  }
}

.nameInputs {
  display: flex;
  padding: 12px 20px 12px 0;

  div:first-child {
    padding-right: 40px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    div:first-child {
      padding-right: 0;
    }
  }
}

.questions {
  transition: min-height 0.25s;
  width: 100%;
  overflow-x: visible;
  position: relative;
  min-height: 182px;
  overflow: hidden;

  &.isTransitioning {
    overflow-x: hidden;

    .question-transition-previous,
    .question-transition-next {
      opacity: 1;
    }
  }

  &.isWorkBehavior {
    min-height: 225px;
  }

  @media (max-width: 600px) {
    min-height: 310px;
    overflow-x: visible;
  }
}

.question-transition-container {
  position: absolute;
  top: 0;
  width: 100%;
}

.question-transition-current {
  transform: translateX(0);
  transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 9;
}

.question-transition-previous {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 9;
}

.question-transition-next {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 9;
}

.buttons {
  margin: 16px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-button {
  background: #fff;
  padding: 15px;
  margin: 15px;
  border-radius: 3px;
  color: #ffffff;
  font-family: var(--font-lato);
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 3px;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid #fff;
  outline: none;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
  text-decoration: none;
  display: inline-block;
  transform: translateY(-1px);
  text-align: center;
  cursor: pointer;
  transition:
    transform 400ms,
    background 400ms,
    border-color 400ms,
    color 400ms;
  padding: 10px;
  margin: 5px;
  color: #222;
}

.page-button-blue {
  background: $blue;
  border-color: $blue;
  color: white;
}

.button-disabled {
  opacity: 0.5;
  transition: all 0.2s;
  &:before {
    position: absolute;
    left: 100%;
    margin-left: 16px;
    top: 0;
    padding: 10px;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: block;
    background-color: #fff;
    content: 'Answer question before continuing';
    opacity: 0;
    transition: all 0.2s;
    pointer-events: none;
  }
  &:hover {
    opacity: 0.75;
    &:before {
      opacity: 1;
    }
  }
}

.navLink {
  margin: 0 8px;
  cursor: pointer;
}

.header-wrapper {
  margin: 0 16px;
}

.langDropdownContainer {
  max-width: 800px;
  margin: 0 auto;
}

.langDropdownWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
