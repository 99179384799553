@import '@crystal-eyes/constants.scss';

.login {
  display: grid;
  width: 100%;
  min-height: 100%;
  padding: 80px 30px 30px;
  box-sizing: border-box;

  grid-template-columns: 1fr auto 1fr;
  column-gap: 60px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 975px) {
    grid-template-columns: 1fr;

    & > :nth-child(1),
    & > :nth-child(2) {
      display: none;
    }

    .right {
      justify-self: center;
    }
  }
}

.divider {
  height: 489px;
  width: 1px;
  background-color: #c4c4c4;
}

.card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;

  width: 100%;
  min-height: 590px;
}

.left {
  max-width: 507px;
  justify-self: flex-end;

  padding: 30px 40px;
  box-sizing: border-box;
}

.right {
  max-width: 565px;
  padding: 36px 0;
  box-sizing: border-box;
  justify-self: flex-start;
  color: #01293d;

  h2 {
    color: #01293d;
    font-style: normal;
    font-weight: 400;
  }

  .useWorkEmail {
    font-size: 0.9rem !important;
  }
}

.loginArea {
  width: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
}
