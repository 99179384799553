@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1040px;

  .label {
    color: $gray-text;
    font-family: var(--font-lato);
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 4px;

    &.error {
      color: $color-error;
    }
  }

  .textarea {
    background: $white;
    min-height: 154px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    padding: 14px 10px;
    &:focus-visible {
      outline: none;
    }
    &:hover {
      cursor: text;
    }
    &.error {
      border: 1px solid $color-error;
    }
  }
}

.tags-input {
  display: inline-flex;
  margin: 0 auto;
  padding: 5px;
  width: 600px;
  font-size: 14px;
  border: #cecece solid 1px;
  border-radius: 3px;
}

input.invisi-input {
  width: 400px;
  border: 0;
  outline: none;
  &:focus-visible {
    outline: none;
  }

  @media (max-width: $mobile){
    width: 100%;
  }
}

.footnote {
  font-size: 14px;
  color: $gray-text;
  font-family: var(--font-lato);
  font-weight: 300;
  font-style: italic;
  margin-top: 4px;
  line-height: 140%;

  &.error {
    color: $color-error;
  }
}
