@import '@crystal-eyes/constants.scss';

.profileBox {
  display: flex;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #e8f8fe;
    .clickableBlurb {
      background: #e8f8fe;
    }
  }

  .clickableBlurb {
    padding: 8px 16px;
    height: calc(100% - 16px);
  }
  .unknownAttendee {
    display: flex;
    flex-direction: row;
    padding: 16px;
    gap: 22px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .breakWord {
    word-break: break-word;
  }

  .text-pill {
    font-size: 10px;
    padding: 2px 4px;
    line-height: 12px;
  }
}

.svgWrapper {
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
