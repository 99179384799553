@import '@crystal-eyes/constants.scss';

.completenessBox {
  padding: 17px 24px;
  border-radius: 4px;
  border: 1px solid $gray-background;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 48px);
  word-wrap: break-word;

  svg {
    stroke: none;
  }

  .grayHeader {
    font-size: 1.125rem;
    color: $gray-text;
    max-width: 92px;
    text-align: center;
  }

  .browserIcon {
    height: 41px;
    width: 56px;
  }

  .checkmarkIcon {
    height: 12px;
    width: 12px;
  }
}
