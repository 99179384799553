@import '@crystal-eyes/helpers.scss';

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  svg {
    stroke: none;
  }
}
