@import '@crystal-eyes/helpers.scss';
@import '@crystal-eyes/constants.scss';

.ledgerWrapper {
  background: white;
  padding: 24px;
  font-family: var(--font-lato);
  .table {
    width: 100%;
  }
}
