@import '@crystal-eyes/constants.scss';

.column-container {
  div {
    display: flex;
    justify-content: center;
    margin: 0 50px;
  }

  img {
    height: 300px;
  }

  .sub-header {
    margin-top: 50px;
    font-size: 18px;
    color: $gray-text;
  }

  margin-top: 50px;
}

.row {
  display: flex;
  overflow: hidden;
  transition: all;

  transition-duration: 0.5s;

  &.index2 {
    transform: translateX(-400px);
  }
  &.index1 {
    transform: translateX(0px);
  }
  &.index0 {
    transform: translateX(400px);
  }
}

.mask {
  background-color: white;
  clip-path: inset(11% 30% 0% 32%);
}
