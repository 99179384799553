@import '@crystal-eyes/constants.scss';

.container {
  width: 100%;
  height: 100%;
}

@mixin color-mixin($color) {
  stroke: $color;
  fill: none;
}
.D {
  @include color-mixin($disc-D);
}
.Di {
  @include color-mixin($disc-Di);
}
.DI {
  @include color-mixin($disc-DI);
}
.Id {
  @include color-mixin($disc-Id);
}
.I {
  @include color-mixin($disc-I);
}
.Is {
  @include color-mixin($disc-Is);
}
.IS {
  @include color-mixin($disc-IS);
}
.Si {
  @include color-mixin($disc-Si);
}
.S {
  @include color-mixin($disc-S);
}
.Sc {
  @include color-mixin($disc-Sc);
}
.SC {
  @include color-mixin($disc-SC);
}
.Cs {
  @include color-mixin($disc-Cs);
}
.C {
  @include color-mixin($disc-C);
}
.Cd {
  @include color-mixin($disc-Cd);
}
.CD {
  @include color-mixin($disc-CD);
}
.Dc {
  @include color-mixin($disc-Dc);
}
