@import '@crystal-eyes/constants.scss';

.container {
  text-transform: uppercase;
  color: $white;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  padding: 0.2rem 0.8rem;
}

.error {
  background-color: $error;
}

.success {
  background-color: $success;
}

.warning {
  background-color: $warning;
}

.info {
  background-color: $info;
}
