@import '@crystal-eyes/constants.scss';

.sectionHeader {
  display: flex;
  padding: 24px;
  background: $white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
}

.headerTxt {
  font-size: 1.714rem;
  color: $blue;
}

.chevronBack {
  transform: rotate(180deg);
  cursor: pointer;
  margin-bottom: 8px;
}
